@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  width: 100%;
}

.screen-container {
  @apply max-w-screen-2xl px-4 lg:px-8;
}
.page-title {
  @apply text-3xl md:text-4xl font-bold italic mb-2;
}
.page-description {
  @apply text-sm italic mb-10;
}
.section-title {
  @apply text-lg font-bold italic mb-5;
}
.calc-height-screen {
  min-height: calc(100vh - 56px);
}
.footer-menulist {
  @apply mb-3 border-b border-black hover:border-gray-400 transition-all;
}
.colorBadge:checked + label > .colorChecked {
  @apply border-black origin-center scale-[135%] block;
}
.colorBadge:checked + label > .colorChecked2 {
  @apply border-gray-400 origin-center scale-[135%] block;
}
.confirm-underline {
  @apply border-b border-neutral-700;
}

.alex-brush-regular {
  font-family: 'Alex Brush', cursive;
  font-weight: 400;
  font-style: normal;
}

.pinyon-script-regular {
  font-family: 'Pinyon Script', cursive;
  font-weight: 400;
  font-style: normal;
}

@layer base {
  html {
    font-family: 'jaf-bernina-sans', sans-serif;
    font-weight: 400;
  }
}
